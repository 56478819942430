/**
 * Check if the given role is present in the list of roles.
 *
 * @param {string} role - The role to check for
 * @param {Role[]} roles - List of roles to search in (default is an empty array)
 * @returns {boolean} True if the role is found, false otherwise
 */
export function checkIfRolesHasSpecificRole(
  role: string,
  roles: Role[] = [],
): boolean {
  if (roles.length === 0) return false;
  return roles.some((r) => r.role === role) || false;
}

type Role = {
  role: string;
};
